<template>
  <div class="wrapper">
    <!-- this transition animates the entire slide, which is a bit much with the supergraphics -->
    <!--<transition
      :name="transitionName"> -->
    <router-view
      :key="$route.fullPath"
      @prevSlide="prevSlide"
      @nextSlide="nextSlide"/>
      <!--    </transition> -->
  </div>
</template>

<script>
export default {
  name: 'SlidesTransitionWrapper',
  data() {
    return {
      transitionName: 'fade',
    };
  },
  methods: {
    prevSlide() {
      this.transitionName = 'slideprev';
    },
    nextSlide() {
      this.transitionName = 'slidenext';
    },
  },
};
</script>

<style lang="scss" scoped>

.wrapper {
  background-color: white;
}

.slidenext-enter-active, .slideprev-enter-active {
  transition: all 0.6s ease-out;
}
.slidenext-leave-active, .slideprev-leave-active {
  transition: all 0.6s ease-out;
}
.slidenext-enter  {
  transform: translateX(100%);
}
.slidenext-leave-to {
  transform: translateX(-100%);
}

.slideprev-enter  {
  transform: translateX(-100%);
}
.slideprev-leave-to {
  transform: translateX(100%);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
